<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModulesList',

  components: {
    // Action: () => import('@/components/general/Action'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    // FilterList: () => import('@/components/general/FilterList'),
    Pagination: () => import('@/components/general/Pagination'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {},
        order: {
          active: 'desc',
          name: 'asc'
        },

        limit: 12,
        page: 1,
        lastPage: null
      },

      moduleId: null,
      modulesList: [],

      modalDeactiveOpen: false,
      deactiveModuleId: null,
      modalTitle: ''
    }
  },

  computed: {
    ...mapGetters([ 'isUserRoot' ]),

    canReadModulesList () {
      return this.getContextPermission('modules') === 'read' || this.isUserRoot
    },

    canWrite () {
      return this.getContextPermission('modules') === 'write' || this.isUserRoot
    },

    isSasEnabled () {
      return this.modulesList.some(module => module.alias === 'sas' && module.active === true)
    },

    isRaeEnabled () {
      return this.modulesList.some(module => module.alias === 'rae' && module.active === true)
    },

    isRmEnabled () {
      return this.modulesList.some(module => module.alias === 'rm' && module.active === true)
    },

    modalDescription () {
      if (this.isSasEnabled && this.isRmEnabled) {
        return this.$t('modules:deactivate.rae.complete.description')
      } else if (this.isSasEnabled) {
        return this.$t('modules:deactivate.rae.only.sas.description')
      } else if (this.isRmEnabled) {
        return this.$t('modules:deactivate.rae.only.rm.description')
      } else {
        return this.$t('modules:deactivate.rae.default.description')
      }
    }
  },

  watch: {
    'pagination.page' () {
      this.getModuleList()
    }
  },

  created () {
    if (!this.canReadModulesList) {
      this.setFeedback({ message: this.$t('global.error.permission') })
    }

    this.getModuleList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetModulesList',
      'attemptToggleActiveModule'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    toggleActive (moduleId, toggleFlag) {
      this.setFetching(true)

      this.attemptToggleActiveModule(moduleId).then(() => {
        this.getModuleList()

        if (!toggleFlag) {
          this.setFeedback({ message: this.$t('modules:feedback.toggle.activate.success') })
        } else {
          this.setFeedback({ message: this.$t('modules:feedback.toggle.deactivate.success') })
        }
      }).catch(() => {
        this.setFeedback({ message: this.$t('global.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    getModuleList () {
      this.setFetching(true)

      this.attemptGetModulesList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
        this.modulesList = pagination.data
      }).finally(() => {
        this.setFetching(false)
      })
    },

    canActivate (active) {
      return this.canWrite && !active
    },

    canDeactivate (active) {
      return this.canWrite && active
    },

    confirmDeactive (moduleId, moduleAlias) {
      if (moduleAlias === 'rae') {
        this.modalTitle = this.$t('modules:deactivate.rae.title')
        this.modalDeactiveOpen = true
      }

      this.deactiveModuleId = moduleId
    },

    cancelDeactive () {
      this.modalDeactiveOpen = false
    },

    toggleActiveModal () {
      this.toggleActive(this.deactiveModuleId, true)
      this.modalDeactiveOpen = false
    },

    canShowActions (active, alias) {
      if ((alias === 'sas' || alias === 'rm') && active === false) {
        return this.canActivate(active) && this.isRaeEnabled
      }

      return true
    }
  }
}
</script>

<template>
  <section
    v-if="canReadModulesList"
    class="modules main-content"
  >
    <ContentHeader
      :title="$t('modules:header.title')"
      :description="$t('modules:header.description')"
      background="/assets/images/themes/default/png/modules_banner.png"
    />

    <!--
      <FilterList>
        <Action
          v-if="canWrite"
          slot="button"
          primary
          large
          fixed-width
          type="button"
          :text="$t('modules.list:btn.add')"
          @click="createNewModule"
        />
      </FilterList>
    -->

    <div
      v-if="modulesList.length > 0"
      class="center modules-table"
    >
      <Datatable :items="modulesList">
        <template slot="thead">
          <tr>
            <th
              class="th"
              width="170"
            >
              {{ $t('modules.list:datatable.header.1') }}
            </th>

            <th
              class="th text-center"
              width="80"
            >
              {{ $t('modules.list:datatable.header.2') }}
            </th>

            <th
              class="th"
              width="40"
            />
          </tr>
        </template>

        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ $t('modules.list:datatable.aliases.' + props.item.alias) }}</span>
            </td>

            <td class="td text-center">
              <span
                v-if="!!props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>

              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>

            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                v-show="canShowActions(props.item.active, props.item.alias)"
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  v-if="canActivate(props.item.active)"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />

                <DropdownLink
                  v-else-if="canDeactivate(props.item.active) && props.item.alias === 'rae' && (isSasEnabled || isRmEnabled)"
                  :text="$t('global:deactivate')"
                  @click="confirmDeactive(props.item.id, props.item.alias)"
                />

                <DropdownLink
                  v-else-if="canDeactivate(props.item.active)"
                  :text="$t('global:deactivate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>

      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <ModalConfirm
      :active="modalDeactiveOpen"
      :title="modalTitle"
      :description="modalDescription"
      @confirmAction="toggleActiveModal"
      @cancelAction="cancelDeactive"
    />
  </section>
</template>

<style lang="scss" scoped>
.modules .modules-table {
  max-width: 1000px;
  margin: 32px auto;
  width: 1000px;
}
</style>
